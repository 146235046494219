import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchUser,
  fetchUserbyId,
  fetchLoggedUser,
  addUser,
  updateUser,
  deleteUser,
} from "./Users";

export const UserDetailsAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchUser: fetchUser(build),
    fetchUserbyId: fetchUserbyId(build),
    fetchLoggedUser: fetchLoggedUser(build),
    addUser: addUser(build),
    updateUser: updateUser(build),
    deleteUser: deleteUser(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchUserQuery,
  useLazyFetchUserbyIdQuery,
  useLazyFetchLoggedUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = UserDetailsAPI;
