import { object, string } from "yup";

export const validationSchema = object().shape({
  houseName: string()
    .required("House name is required")
    .matches(
      /^[a-zA-Z0-9., ]+$/,
      "Only letters, numbers and spaces are allowed"
    ),
  districtId: string().required("District is required"),
  city: string()
    .required("City is required")
    .matches(
      /^[a-zA-Z0-9., ]+$/,
      "Only letters, numbers and spaces are allowed"
    ),
  postOffice: string()
    .required("Post Office is required")
    .matches(
      /^[a-zA-Z0-9., ]+$/,
      "Only letters, numbers and spaces are allowed"
    ),
  pinCode: string()
    .required("Pincode is required")
    .matches(/^[1-9][0-9]{5}$/, "Enter a valid pincode"),
});
