import MasterServiceAPI from "../../MasterServiceAPI";
import {
  fetchPreApprovalList,
  fetchPreApprovalbyId,
  fetchPreApprovalPendingList,
  approvePreApprovalProcess,
  rejectPreApprovalProcess,
  assignPreApprovalProcess,
  fetchUserByRole,
  fetchApprovalProcessFlowbyId,
  fetchAllVesselList,
  fetchVesselByName,
} from "./Preapproval";

export const PreapprovalAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchPreApprovalList: fetchPreApprovalList(build),
    fetchPreApprovalPendingList: fetchPreApprovalPendingList(build),
    fetchPreApprovalbyId: fetchPreApprovalbyId(build),
    approvePreApprovalProcess: approvePreApprovalProcess(build),
    rejectPreApprovalProcess: rejectPreApprovalProcess(build),
    assignPreApprovalProcess: assignPreApprovalProcess(build),
    fetchUserByRole: fetchUserByRole(build),
    fetchApprovalProcessFlowbyId: fetchApprovalProcessFlowbyId(build),
    fetchAllVesselList: fetchAllVesselList(build),
    fetchVesselByName: fetchVesselByName(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchPreApprovalListQuery,
  useLazyFetchPreApprovalPendingListQuery,
  useLazyFetchPreApprovalbyIdQuery,
  useApprovePreApprovalProcessMutation,
  useRejectPreApprovalProcessMutation,
  useAssignPreApprovalProcessMutation,
  useLazyFetchUserByRoleQuery,
  useLazyFetchApprovalProcessFlowbyIdQuery,
  useLazyFetchAllVesselListQuery,
  useLazyFetchVesselByNameQuery,
} = PreapprovalAPI;
