import { Routes, Route } from "react-router-dom";
import React, { Suspense, useEffect, useReducer } from "react";
import PrivateRoutes from "./PrivateRoutes";
import { VesselOwnerRoutes, RegistrationRoutes } from "./VesselOwnerRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { PcRoutes } from "./PcRoutes";
import { SurveyorRoutes } from "./SurveyorRoutes";
import { NavalArchitectRoutes } from "./NavalArchitectRoutes";
import { RARoutes } from "./RARoutes";
import Loader from "../components/Loader";
import { UserRoutes } from "./UserRoutes";
import { YardRoutes } from "./YardRoutes";
import { BuilderRoutes } from "./BuilderRoutes";
import { getRole } from "../utils/utility";
import { ADMIN } from "../utils/constants";
import UserService from "./../services/UserService";

const index = () => {
  const role = getRole();
  console.log(role, "OP");

  return (
    <div>
      <Suspense fallback={<Loader isRouterLoader={true} />}>
        <Routes>
          <Route>
            {UserRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"admin"}>{el.component}</PrivateRoutes>
                }
              />
            ))}
            {UserService.hasRole(["admin"]) &&
              AdminRoutes.map((el) => (
                <Route
                  key={el.name}
                  path={el.path}
                  element={
                    <PrivateRoutes role={"admin"}>
                      {el.component}
                      {""}
                    </PrivateRoutes>
                  }
                />
              ))}

            {VesselOwnerRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"vesselowner"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}

            {PcRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"admin"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
            {SurveyorRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"admin"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
            {NavalArchitectRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"admin"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
            {RARoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"admin"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
            {YardRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"yard"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
            {BuilderRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"builder"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
            {RegistrationRoutes.map((el) => (
              <Route
                key={el.name}
                path={el.path}
                element={
                  <PrivateRoutes role={"builder"}>
                    {el.component}
                    {""}
                  </PrivateRoutes>
                }
              />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default index;
