import MasterServiceAPI from "../../MasterServiceAPI";
import {
  addRegistration,
  approveRegistrationProcess,
  deleteRegistration,
  fetchPendingRegistration,
  fetchRegistration,
  fetchRegistrationById,
  rejectRegistrationProcess,
  updateRegistration,
  addRegistrationProcess,
  approveRegistrationProcessWithoutSurveyor
} from "./registration";

export const RegistrationAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addRegistration: addRegistration(build),
    fetchRegistration: fetchRegistration(build),
    fetchRegistrationById: fetchRegistrationById(build),
    updateRegistration: updateRegistration(build),
    deleteRegistration: deleteRegistration(build),
    fetchPendingRegistration: fetchPendingRegistration(build),
    addRegistrationProcess: addRegistrationProcess(build),
    approveRegistrationProcess: approveRegistrationProcess(build),
    approveRegistrationProcessWithoutSurveyor:approveRegistrationProcessWithoutSurveyor(build),
    rejectRegistrationProcess: rejectRegistrationProcess(build),
  }),
  overrideExisting: false,
});

export const {
  useAddRegistrationMutation,
  useLazyFetchRegistrationQuery,
  useLazyFetchRegistrationByIdQuery,
  useUpdateRegistrationMutation,
  useDeleteRegistrationMutation,
  useLazyFetchPendingRegistrationQuery,
  useAddRegistrationProcessMutation,
  useApproveRegistrationProcessMutation,
  useApproveRegistrationProcessWithoutSurveyorMutation,
  useRejectRegistrationProcessMutation,
} = RegistrationAPI;
