import { useFormik } from "formik";
import React, { useRef } from "react";
import TextBox from "./TextBox";
import { validationSchema } from "./../pages/VesselOwner/Registration/validationSchema";
import { object, string } from "yup";
import { Button, Row } from "react-bootstrap";
import TextBoxLive from "./TextBoxLive";

const NaDocumentVerification = () => {
  const initialValues = {
    date_of_signature: "",
    place: "",
  };
  const inputfile = useRef(null);
  const handleSubmit = async (values) => {};

  const validationSchema = object().shape({
    date_of_signature: string().required("Date of signature is required"),
    place: string().required("Place is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  console.log(formik.errors, "asdd");
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="d-flex gap-5 w-100  justify-content-evenly align-items-center"
      >
        <TextBoxLive
          label={"Date of signature"}
          id="date_of_signature"
          name="date_of_signature"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="date"
          required={true}
          row={3}
          error={formik.errors?.date_of_signature}
          touched={formik.touched?.date_of_signature}
        />

        <TextBoxLive
          label={"Place"}
          id="place"
          name={"place"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required={true}
          row={3}
          error={formik.errors?.place}
          touched={formik.touched?.place}
        />
        <input type="file" className="d-none" ref={inputfile} />
        <Button
          className="btn btn-outline mt-3"
          onClick={() => inputfile.current.click()}
        >
          Digital Signature
        </Button>
      </form>
    </>
  );
};

export default NaDocumentVerification;
