export const generatePdf = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/vesselConsent/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};
export const getSurveyDeclarationPdf = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/surveyDeclaration/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};
export const getAnnualSurvey = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/AnnualSurvey/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};

export const getCertificateofSurvey = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/surveyCertificate/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};
export const getRegistrationCertificate = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/registrationCertificate/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};

export const getCarvingNote = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/carvingNote/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};

export const getPreApprovalPreview = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/preapprovalpreview/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};

export const getSurveyPreview = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/surveypreview/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};
