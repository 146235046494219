import Form from "react-bootstrap/Form";
import React from "react";
import PropTypes from "prop-types";
import "./style.css";
function CheckBox({ id, label, onChange, value, isDisabled, checked }) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "90px" }}>
      <Form.Check
        type={"checkbox"}
        id={id}
        label={label}
        onChange={onChange}
        isValid={false}
        value={value}
        checked={checked}
        disabled={isDisabled}
      />
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
