import {
  ACTIVE,
  RA,
  VESSEL_OWNER,
  PORT_CONSERVATOR,
  NAVAL_ARCHITECTURE,
  SURVEYOR,
  BUILDER,
  YARD,
  ADMIN
} from "./constants";

import UserService from "../services/UserService";
export const getRole = () => {
  if (UserService.hasRole(["port-conservator"])) {
    return PORT_CONSERVATOR;    
  } else if(UserService.hasRole(["registering-authority"])){
    return RA;
  }else if (UserService.hasRole(["naval-architect"])) {
    return NAVAL_ARCHITECTURE;
  } else if (UserService.hasRole(["registering-authority"])) {
    return RA;
  } else if (UserService.hasRole(["surveyor"])) {
    return SURVEYOR;
  }else if (UserService.hasRole(["vessel-owner"])) {
    return VESSEL_OWNER;
  }else if (UserService.hasRole(["builder"])) {
    return BUILDER;
  }else if (UserService.hasRole(["yard-owner"])) {
    return YARD;
  }else if (UserService.hasRole(["admin"])) {
    return ADMIN;
  }  
};
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error setting item to local storage:", error);
  }
};

// Get item from local storage
export const getLocalStorageItem = (key, defaultValue = null) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error("Error getting item from local storage:", error);
    return defaultValue;
  }
};

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}-${month}-${year}`;
}


console.log('Key Role'+getRole());