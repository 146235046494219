import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import CommonModal from "../../../components/CommonModal";
import CommonPageTitle from "../../../components/CommonPageTitle";
import TextArea from "../../../components/TextArea";
import UserService from "../../../services/UserService";
import "../../style.scss";
import "./style.scss";
import Form from "react-bootstrap/Form";

import CommonFileAccordian from "../../../components/CommonFileAccordian";
import CommonRequestDetails from "../../../components/CommonRequestDetails";
import TextBox from "../../../components/TextBox";
import { useLazyFetchUserbyIdQuery } from "../../../services/modules/admin/users";
import { useLazyFetchVesselBuilderbyIdQuery } from "../../../services/modules/admin/vessel";
import {
  useLazyFetchVesselAdditionalDetailsbyIdQuery,
  useLazyFetchVesselbyIdQuery,
} from "../../../services/modules/vesselowner/preapproval";
import {
  useApproveStageVerificationbyNAMutation,
  useRejectStageVerificationbyNAMutation,
  useLazyFetchStageVerificationbyIdQuery,
  useUpdateStageVerificationInformMutation,
} from "../../../services/modules/StageVerification";
import { NAVAL_ARCHITECTURE, VESSEL_OWNER } from "../../../utils/constants";
import { getRole } from "../../../utils/utility";
import { Accordion, ListGroup } from "react-bootstrap";
import StageVerificationPreApproval from "../../../components/StageVerificationPreApproval";

function StageVerificationDetails({ ...props }) {
  const [isApproved, setIsApproved] = useState(true);
  const role = getRole();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Id: stageVerificationId } = state || {};
  const { vesselId } = state || {};
  const { stageStatus } = state || {};
  const [userInfo, setUserInfo] = useState(null);
  const [vesselData, setVesselData] = useState(null);
  const [vesselDataAdditional, setVesselDataAdditional] = useState(null);

  const [vesselAddId,setVesselAddId] =useState(null);

  const [propulsionEngine, setPropulsionEngine] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [builderId, setBuilderId] = useState("");

  const [builderDetails, setBuilderDetails] = useState([]);
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [stageVerificationDetails, setStageVerificationDetails] = useState([]);

  const [status, setStatus] = useState(false);

  const [type, setType] = useState(null);

  const [approveStageVerificationbyNA] =
    useApproveStageVerificationbyNAMutation();
  const [rejectStageVerificationbyNA] =
    useRejectStageVerificationbyNAMutation();

  const [fetchVesselbyId, { data: vesselDataFetched }] =
    useLazyFetchVesselbyIdQuery();

  const [fetchVesselAdditionalDetailsbyId, { data: vesselAdditionalData }] =
    useLazyFetchVesselAdditionalDetailsbyIdQuery();

  const [fetchVesselBuilderbyId, { data: builderData }] =
    useLazyFetchVesselBuilderbyIdQuery();

  const [fetchUserbyId, { data: userDataById }] = useLazyFetchUserbyIdQuery();

  const [fetchStageVerificationbyId, { data: stageVerificationDatabyId }] =
    useLazyFetchStageVerificationbyIdQuery();

  const [updateStageVerificationInform] =
    useUpdateStageVerificationInformMutation();

  const [message, setMessage] = useState({
    title: "",
    isSuccess: true,
    show: false,
  });

  const onHandleClickBack = () => navigate(-1);

  const initialValues = {
    remarks: "",
    naval_architect: "",
    expectedVisitDate: "",
  };

  const validationSchema = isApproved
    ? Yup.object({
        remarks: Yup.string().when("action", {
          is: (action) => action === "approve" || action === "reject",
          then: () => Yup.string().required("Remarks field is required!"),
        }),
        expectedVisitDate: Yup.date().when("action", {
          is: (action) => action === "inform",
          then: () => Yup.date().required("Visit date is required"),
        }),
      })
    : Yup.object({
        remarks: Yup.string().required("Remarks field is required!"),
      });

  // TODO: Refactor
  const onSubmit = async (values, { setSubmitting }) => {
    if (formik.values.action === "approve") {
      const { remarks } = values;
      if (remarks) {
        let postData = {
          remarks: [
            {
              remark: remarks,
              action: "approve",
            },
          ],
        };

        try {
          const response = await approveStageVerificationbyNA({
            data: postData,
            id: stageVerificationId,
          });

          if (response?.error) {
            setMessage({
              title: response?.error?.data?.message,
              show: true,
              isSuccess: false,
            });
          } else {
            setMessage({
              title: response?.data?.message,
              show: true,
              isSuccess: true,
            });
          }
        } catch (error) {}
        setSubmitting(false);
      }
    } else if (formik.values.action === "reject") {
      onReject(values);
    } else if (formik.values.action === "inform") {
      const postData = {
        expectedVisitDate: formik.values.expectedVisitDate,
      };
      const res = await updateStageVerificationInform({
        id: stageVerificationId,
        data: postData,
      });

      if (res?.error) {
        setMessage({
          title: res?.error?.data?.message,
          show: true,
          isSuccess: false,
        });
      } else {
        setMessage({
          title: res?.data?.message,
          show: true,
          isSuccess: true,
        });
      }
      return;
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const { setFieldValue } = formik;

  const onReject = async () => {
    const { remarks } = formik.values;
    if (remarks) {
      let postData = {
        remarks: [
          {
            remark: remarks,
            action: "reject",
          },
        ],
      };

      try {
        const response = await rejectStageVerificationbyNA({
          data: postData,
          id: stageVerificationId,
        });
        if (response?.error) {
          setMessage({
            title: response?.error?.data?.message,
            show: true,
            isSuccess: false,
          });
        } else {
          setMessage({
            title: response?.data?.message,
            show: true,
            isSuccess: true,
          });
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchStageVerificationbyId({ id: stageVerificationId });
  }, [stageVerificationId, fetchStageVerificationbyId]);

  useEffect(() => {
    fetchVesselbyId({ id: vesselId });
  }, [fetchVesselbyId, vesselId]);

  useEffect(() => {
    if (
      stageVerificationDatabyId?.statusCode === 201 ||
      stageVerificationDatabyId?.statusCode === 200
    ) {
      const { result } = stageVerificationDatabyId;
      setStageVerificationDetails(stageVerificationDatabyId?.result);
      console.log("stageVerificationDatabyId",stageVerificationDatabyId?.result?.status);
      const stageStatus = stageVerificationDatabyId?.result?.status;
      
        setStatus(stageVerificationDatabyId?.result?.status);
        setType(stageVerificationDatabyId?.result?.type);
      
      
      if (result?.expectedVisitDate)
        formik.setFieldValue(
          "expectedVisitDate",
          convertDate(result.expectedVisitDate)
        );
    }
  }, [stageVerificationDatabyId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetchPreApprovalbyId({
  //         id: stageVerificationId,
  //       });
  //       const { approvalProcessFlowId } = response?.data?.result;
  //
  //       const approvalProcessFlowResponse = await fetchApprovalProcessFlowbyId({
  //         id: approvalProcessFlowId,
  //       });
  //       setApprovalProcessFlowData(approvalProcessFlowResponse?.data?.result);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //
  //   fetchData();
  // }, [stageVerificationId, fetchPreApprovalbyId, fetchApprovalProcessFlowbyId]);

  useEffect(() => {
    if (
      vesselDataFetched?.statusCode === 201 ||
      vesselDataFetched?.statusCode === 200
    ) {
      setUserId(vesselDataFetched?.result[0]?.ownerId);
      const { vesselAdditionalDetail } = vesselDataFetched?.result[0];
      setVesselData(vesselDataFetched?.result[0]);
      setVesselAddId(vesselAdditionalDetail._id)
      fetchVesselAdditionalDetailsbyId({
        id: vesselAdditionalDetail._id,
      });
    }
  }, [vesselDataFetched, fetchVesselAdditionalDetailsbyId]);
  useEffect(() => {
    if (
      vesselAdditionalData?.statusCode === 201 ||
      vesselAdditionalData?.statusCode === 200
    ) {
      setVesselDataAdditional(vesselAdditionalData?.result);
      setPropulsionEngine(vesselAdditionalData?.result?.propulsionOfEngines);
      setEquipments(vesselAdditionalData?.result?.equipment);
      setBuilderId(vesselAdditionalData?.result?.hulls?.builderId);
    }
  }, [vesselAdditionalData]);

  useEffect(() => {
    fetchUserbyId({ id: userId });
  }, [userId, fetchUserbyId]);

  useEffect(() => {
    if (userDataById?.statusCode === 201 || userDataById?.statusCode === 200) {
      setUserDetails(userDataById?.result[0]);
    }
  }, [userDataById]);

  useEffect(() => {
    fetchVesselBuilderbyId({ id: builderId });
  }, [builderId, fetchVesselBuilderbyId]);

  useEffect(() => {
    if (builderData?.statusCode === 201 || builderData?.statusCode === 200) {
      setBuilderDetails(builderData?.result);
    }
  }, [builderData]);

  useEffect(() => {
    if (userInfo) {
      setFieldValue("owner_name", userInfo?.given_name);
    }
  }, [userInfo, setFieldValue]);
  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserInfo();
      if (userDetail) {
        setUserInfo(userDetail);
      }
    };
    getUserInfo();
  }, []);
  const isTouched = (fieldName, formik) => {
    const isCondition = formik.touched[fieldName] && formik.errors[fieldName];
    return isCondition;
  };
  const handleClose = () => {
    setMessage((message) => ({
      ...message,
      show: false,
    }));
   
      //Navigate based on the type
      switch (type) {
        case "Keel laying":
          navigate("/keel-laying-requests");
          break;
        case "Launching":
          navigate("/launching-requests");
          break;
        case "Stability":
          navigate("/stability-requests");
          break;
        default:
          break;
      }
    
  };

  const convertDate = (date) => {
    if (date) return new Date(date)?.toISOString()?.split("T")?.at(0);
    else return null;
  };

  return (
    <Container>
      <Breadcrumb crumbs={props.crumbs} />
      <CommonModal
        {...message}
        description=""
        buttonText="OK"
        handleClose={handleClose}
      />
      <CommonPageTitle
        title="Back"
        arrowback={true}
        handleClickBack={onHandleClickBack}
      />
      {role === VESSEL_OWNER ? (
        <StageVerificationPreApproval
          stageVerificationDetails={stageVerificationDetails}
          // stageStatus={stageStatus}
          vesselData={vesselData}
          vesselAdditionalDetail={vesselAddId}
          
        />
      ) : null}
      {role === NAVAL_ARCHITECTURE ? (
        <>
          <CommonRequestDetails
            userDetails={userDetails}
            vesselData={vesselData}
            builderDetails={builderDetails}
            vesselDataAdditional={vesselDataAdditional}
            propulsionEngine={propulsionEngine}
            equipments={equipments}
          />
          <CommonFileAccordian vesselDataAdditional={vesselDataAdditional} />
        </>
      ) : null}
      {role === NAVAL_ARCHITECTURE && stageVerificationDetails ? (
        <Container className="p-0">
          <Row>
            <form className="forms" onSubmit={formik.handleSubmit}>
              <Row>
                <div className="forms py-3 px-4">
                  <div className="card stage-verification">
                    <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Stage Verification</Accordion.Header>
                        <Accordion.Body>
                          <FormikProvider value={formik}>
                            <Row className="py-2">
                              <ListGroup>
                                <ListGroup.Item>
                                  <Row className="px-5 py-1">
                                    <label>
                                      {stageVerificationDetails.type}
                                    </label>
                                  </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <Row className="align-items-center">
                                    <Col md={6}>
                                      <span>
                                        Due Date:{" "}
                                        {convertDate(
                                          stageVerificationDetails.dueDate
                                        )}
                                      </span>
                                    </Col>
                                    <Col md={4}>
                                      <div className="text-box">
                                        <TextBox
                                          id="expectedVisitDate"
                                          name="expectedVisitDate"
                                          type="date"
                                          required={true}
                                          row={3}
                                          onChange={formik.handleChange}
                                          max={convertDate(
                                            stageVerificationDetails.dueDate
                                          )}
                                          value={
                                            formik.values.expectedVisitDate
                                          }
                                          error={
                                            formik.errors?.expectedVisitDate
                                          }
                                          touched={
                                            formik.touched?.expectedVisitDate
                                          }
                                          isDisabled={status ==="Informed" || status ==="Approved"}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <button
                                        type="submit"
                                        className="link-button mx-auto"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "action",
                                            "inform"
                                          );
                                        }}
                                        disabled={status ==="Informed" ||status ==="Approved"}
                                      >
                                        Inform
                                      </button>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                                {stageVerificationDetails?.checkList?.map(
                                  (item, index) => (
                                    <ListGroup.Item key={index}>
                                      <div className="py-2 d-flex justify-content-between">
                                        <div className="d-flex flex-grow-1 gap-2 align-items-center">
                                          <Form.Check
                                            checked={item.status}
                                            label={item.value}
                                            disabled={status ==="Informed" ||status ==="Approved"}
                                          />
                                        </div>
                                      </div>
                                    </ListGroup.Item>
                                  )
                                )}
                              </ListGroup>
                            </Row>
                          </FormikProvider>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <Row>
                    <Col md={12}>
                      <div className="text-box">
                        <TextArea
                          label="Remarks"
                          id="remarks"
                          name="remarks"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.remarks}
                          touched={isTouched("remarks", formik)}
                          error={formik.errors.remarks}
                          required={true}
                          row={6}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row className="button-container">
                <Col className="btn-col address-button d-flex justify-content-center gap-2">
                  <Button
                    label="Re Submit"
                    type="submit"
                    className="btn-danger btn-outline shadow-none"
                    onClick={() => {
                      formik.setFieldValue("action", "reject");
                      setIsApproved(false);
                    }}
                  />
                  <Button
                    label="Approve"
                    className="btn-default btn-sucess shadow-none"
                    type="submit"
                    onClick={() => {
                      formik.setFieldValue("action", "approve");
                      setIsApproved(true);
                    }}
                    disabled={status !=="Informed"}
                  />
                </Col>
              </Row>
            </form>
          </Row>
        </Container>
      ) : null}
    </Container>
  );
}

export default StageVerificationDetails;
