import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Col } from "react-bootstrap";
import { MdDelete as DeleteIcon } from "react-icons/md";

function FileSelector({ label, name, required, onChange, error, ...props }) {
  return (
    <div className="file-selector">
      {label && (
        <label className="file-selector-label" htmlFor={name}>
          {label}
          {required && <span className="required-indicator">*</span>}
        </label>
      )}
      <div className="file-input-container">
        <input
          type="file"
          className="file-input"
          onChange={onChange}
          name={name}
          required={required}
          {...props}
        />
        <FontAwesomeIcon icon={faPaperclip} className="fa-fw file-clip-icon" />
      </div>
      {error && <span className="error-message">{error}</span>}
    </div>
  );
}
function DummyFile({
  fileName = "",
  showDelete = false,
  onDelete = () => {},
  label,
}) {
  return (
    <Col className="dummy-file-container">
      {label && <span className="dummy-file-label">{label}</span>}
      <div className="dummy-file">
        <span className="dummy-file-name px-3">{fileName}</span>
        {showDelete ? (
          <button
            type="button"
            className="dummy-file-delete"
            onClick={onDelete}
          >
            <DeleteIcon size={"1.25rem"} />
          </button>
        ) : null}
      </div>
    </Col>
  );
}

export default FileSelector;
export { DummyFile };
