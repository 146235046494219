import { useNavigate, useSearchParams } from "react-router-dom";
import { Config } from "../config";
import { Button, Col, Container, Row } from "react-bootstrap";
import CommonPageTitle from "./CommonPageTitle";
import Breadcrumb from "./Breadcrumb";
import { useLazyDownloadFileQuery } from "../services/modules/common/file";
import { useEffect, useState } from "react";
import { getRole } from "./../utils/utility";
import { NAVAL_ARCHITECTURE } from "../utils/constants";
import ReactDatePicker from "react-datepicker";
import TextBox from "./TextBox";
import NaDocumentVerification from "./NaDocumentVerification";

function DocumentVerification({ crumbs }) {
  const [searchParams] = useSearchParams();
  const [downloadFile, { data }] = useLazyDownloadFileQuery();
  const [fileUrl, setFileUrl] = useState("");
  const role = getRole();

  const fileName = searchParams.get("fileName");

  console.log(searchParams);

  const baseUrl = Config.HOST_URL;
  const navigate = useNavigate();

  const onHandleClickBack = () => navigate(-1);

  useEffect(() => {
    if (fileName) {
      downloadFile({ fileName })
        .then((response) => {
          setFileUrl(response.data);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  }, [fileName, downloadFile]);

  return (
    <Container className="dashboard-container">
      <Breadcrumb crumbs={crumbs} />
      <CommonPageTitle
        title="Back"
        arrowback={true}
        handleClickBack={onHandleClickBack}
      />
      {fileName && (
        <Row className="h-100">
          <iframe
            title={fileName}
            src={fileUrl}
            height={"80%"}
            width={"100%"}
          />
          {role === NAVAL_ARCHITECTURE ? (
            <>
              <div className="d-flex ">
                <NaDocumentVerification />
              </div>
              <Row className="button-container mt-3">
                <Col className="btn-col address-button d-flex justify-content-center gap-2">
                  <Button
                    className="btn-danger btn-outline shadow-none"
                    onClick={() => {}}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="btn-danger btn-outline shadow-none"
                    onClick={() => {}}
                  >
                    ReSubmit
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => {}}
                    className="btn-default btn-sucess shadow-none"
                  >
                    Approve
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Row>
      )}
    </Container>
  );
}

export default DocumentVerification;
