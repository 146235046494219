import React, { useEffect, useState, useReducer, lazy } from "react";
import Sidemenu from "../components/sideBar/sidebar";
import Navbar from "../components/sideBar/navbar";
import { AdminRoutes } from "./AdminRoutes";
import { PcRoutes } from "./PcRoutes";
import { SurveyorRoutes } from "./SurveyorRoutes";
import { NavalArchitectRoutes } from "./NavalArchitectRoutes";
import { RARoutes } from "./RARoutes";
import { Outlet } from "react-router-dom";
import { UserRoutes } from "./UserRoutes";
import UserService from "../services/UserService";
import { VesselOwnerRoutes } from "./VesselOwnerRoutes";
import { RegistrationRoutes } from "./VesselOwnerRoutes";
import { BuilderRoutes } from "./BuilderRoutes";
import { YardRoutes } from "./YardRoutes";
import { useLazyFetchLoggedUserQuery } from "../services/modules/admin/users";
import Loader from "../components/Loader";
import Registration from "../pages/VesselOwner/Registration";
import { useSelector } from "react-redux";

function PrivateRoutes({ children }) {
  const [routes, setRoutes] = useState("");
  const [registrationNeeded, setRegistrationNeeded] = useState(false);
  const [roleCode, setRoleCode] = useState("");
  const [
    fetchLoggedUser,
    { data: userData, isSuccess: isUserSuccess, isLoading: isUserLoading },
  ] = useLazyFetchLoggedUserQuery();

  useEffect(() => {
    if (UserService.hasRole(["vessel-owner", "builder", "yard-owner"])) {
      fetchLoggedUser({});
    }
  }, []);

  useEffect(() => {
    if (userData?.result?.length === 0) {
      setRegistrationNeeded(true);
    } else if (userData?.result[0]) {
      setRoleCode(userData.result[0].role?.roleCode || "");
    }
  }, [userData]);

  useEffect(() => {
    if (roleCode.length > 0) {
      setRegistrationNeeded(false);
    }
  }, [roleCode]);

  const handleRouteChange = (roleCode) => {
    setRoleCode(roleCode);
    fetchLoggedUser({});
  };

  useEffect(() => {
    setRoutes("");
    if (registrationNeeded) {
      setRoutes(RegistrationRoutes);
    } else if (roleCode) {
      switch (roleCode) {
        case "admin":
          setRoutes(AdminRoutes);
          break;
        case "vessel-owner":
          setRoutes(VesselOwnerRoutes);
          break;
        case "yard-owner":
          setRoutes(YardRoutes);
          break;
        case "port-conservator":
          setRoutes(PcRoutes);
          break;
        case "registering-authority":
          setRoutes(RARoutes);
          break;
        case "naval-architect":
          setRoutes(NavalArchitectRoutes);
          break;
        case "surveyor":
          setRoutes(SurveyorRoutes);
          break;
        case "builder":
          setRoutes(BuilderRoutes);
          break;
        default:
          break;
      }
    } else {
      if (UserService.hasRole(["admin"])) {
        setRoutes(AdminRoutes);
      } else if (UserService.hasRole(["port-conservator"])) {
        setRoutes(PcRoutes);
      } else if (UserService.hasRole(["registering-authority"])) {
        setRoutes(RARoutes);
      } else if (UserService.hasRole(["naval-architect"])) {
        setRoutes(NavalArchitectRoutes);
      } else if (UserService.hasRole(["surveyor"])) {
        setRoutes(SurveyorRoutes);
      } else if (UserService.hasRole(["builder"])) {
        setRoutes(BuilderRoutes);
      } else if (UserService.hasRole(["vessel-owner"])) {
        setRoutes(VesselOwnerRoutes);
      } else if (UserService.hasRole(["yard-owner"])) {
        setRoutes(YardRoutes);
      } else {
        setRoutes(UserRoutes);
      }
    }
  }, [registrationNeeded, roleCode]);


  const getValidate = ({ children }) => {
    if (UserService.isLoggedIn()) {
      return children;
    }
    return null;
  };

  if (isUserLoading) return <Loader />;
  return (
    <div id="root">
      {registrationNeeded == true && (
        <div>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <div className="layout-page ms-0">
                {/* <Navbar /> */}
                <Registration
                  crumbs="Registration"
                  changeRoutes={handleRouteChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {registrationNeeded == false && (
        <div>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Sidemenu routes={routes} />
              <div className="layout-page">
                <Navbar />
                <Outlet />
                {getValidate({ children })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrivateRoutes;
