import MasterServiceAPI from "../../MasterServiceAPI";
import {
  submitStageVerificationbyNA,
  requestForStageVerificationbyVO,
  informStageVerificationbyNA,
  approveStageVerificationbyNA,
  fetchStageVerificationPendingList,
  fetchStageVerificationbyId,
  updateStageVerificationRequest,
  updateStageVerificationInform,
  updateStageVerificationApprove,
  rejectStageVerificationbyNA,
} from "./StageVerification";

export const StageVerificationAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    submitStageVerificationbyNA: submitStageVerificationbyNA(build),
    requestForStageVerificationbyVO: requestForStageVerificationbyVO(build),
    informStageVerificationbyNA: informStageVerificationbyNA(build),
    approveStageVerificationbyNA: approveStageVerificationbyNA(build),
    fetchStageVerificationPendingList: fetchStageVerificationPendingList(build),
    fetchStageVerificationbyId: fetchStageVerificationbyId(build),
    updateStageVerificationRequest: updateStageVerificationRequest(build),
    updateStageVerificationInform: updateStageVerificationInform(build),
    updateStageVerificationApprove: updateStageVerificationApprove(build),
    rejectStageVerificationbyNA: rejectStageVerificationbyNA(build),
  }),
  overrideExisting: false,
});

export const {
  useApproveStageVerificationbyNAMutation,
  useInformStageVerificationbyNAMutation,
  useRequestForStageVerificationbyVOMutation,
  useSubmitStageVerificationbyNAMutation,
  useLazyFetchStageVerificationPendingListQuery,
  useLazyFetchStageVerificationbyIdQuery,
  useUpdateStageVerificationApproveMutation,
  useUpdateStageVerificationInformMutation,
  useUpdateStageVerificationRequestMutation,
  useRejectStageVerificationbyNAMutation,
} = StageVerificationAPI;
