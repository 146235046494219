import { useState } from "react";
import TextBox from "./TextBox";

function TextBoxLive({
  onChange,
  label,
  name,
  type,
  required,
  value,
  error,
  touched,
  ...props
}) {
  const [didFocus, setDidFocus] = useState(false);
  const handleFocus = () => setDidFocus(true);

  const showFeedback = (!!didFocus && value && value?.length > 0) || touched;
  return (
    <TextBox
      label={label}
      name={name}
      type={type}
      required={required}
      onChange={onChange}
      value={value}
      onFocus={handleFocus}
      error={error}
      touched={showFeedback}
      {...props}
    />
  );
}
export default TextBoxLive;
