import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Card, Container } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import CommonPageTitle from "../../../components/CommonPageTitle";
import CommonModal from "../../../components/CommonModal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TextBoxLive from "../../../components/TextBoxLive";
import SelectBox from "../../../components/Selectbox";
import Button from "../../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../../../services/UserService";
import { useLazyFetchDistrictsQuery } from "../../../services/modules/admin/district";
import { useAddUserMutation } from "../../../services/modules/admin/users";
import { useLazyFetchRolebyRoleCodeQuery } from "../../../services/modules/admin/role";

import FileSelector, { DummyFile } from "../../../components/FileSelector";
import { useUploadFileMutation } from "../../../services/modules/common/file";
import { setLocalStorageItem } from "../../../utils/utility";
import Loader from "../../../components/Loader";
import { validationSchema } from "./validationSchema";

function Index(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [token, setToken] = useState(UserService.getToken);
  const [userInfo, setUserInfo] = useState(null);
  const [roleCode, setRoleCode] = useState("");
  const handleClickBack = () => {
    navigate("/");
  };
  const [
    fetchDistricts,
    {
      data: districtData,
      isSuccess: isDistrictsSuccess,
      isLoading: isDistrictsLoading,
    },
  ] = useLazyFetchDistrictsQuery();

  const [fetchRolebyRoleCode, { data: roleData, isLoading: roleLoading }] =
    useLazyFetchRolebyRoleCodeQuery();

  useEffect(() => {
    fetchDistricts({});
  }, [fetchDistricts]);
  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserProfile();
      if (userDetail) {
        setUserInfo(userDetail);
      }
    };
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      console.log("userInfo", userInfo);
      getRoleCode();
    }
  }, [userInfo]);
  useEffect(() => {
    if (roleCode.length > 0) {
      fetchRolebyRoleCode({ roleCode: roleCode });
    }
  }, [roleCode]);

  const [addUser, isLoading] = useAddUserMutation();

  const [message, setMessage] = useState({
    title: "",
    isSuccess: true,
    show: false,
  });

  function handleFileSelect(fileInput) {
    const file = fileInput.target.files[0];
    const tempFile = new File([file], file.name, {
      type: file.type,
    });

    setFiles((prev) => {
      return { ...prev, [fileInput.target.name]: tempFile };
    });
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const fileNames = { aadharFile: "", profilePic: "" };
    const fileNamesList = Object.keys(fileNames);

    for (let i = 0; i < fileNamesList.length; i++) {
      const name = fileNamesList[i];
      const formData = new FormData();
      formData.append("file", files[name]);

      const res = await uploadFile({ data: formData });
      if (res?.data?.statusCode === 201) {
        fileNames[name] = res.data.result[0].filename;
        //setFieldValue(name, res.data.result[0].filename);

        // Clear uploaded file from memory
        setFiles((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    const postData = {
      ...values,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      roleId: roleData?.result._id,
      mobile: userInfo?.attributes?.phone?.at(0),
      aadharNo: userInfo?.attributes?.aadharNo?.at(0),
      aadharFile: fileNames.aadharFile,
      profilePic: fileNames.profilePic,
      status: true,
      uid: userInfo.id,
    };
    try {
      const response = await addUser(postData);

      if (response?.error) {
        setMessage({
          title: response?.error?.data?.message,
          show: true,
          isSuccess: false,
        });
      } else {
        setMessage({
          title: "Registration Completed Successfully",
          show: true,
          isSuccess: true,
        });
      }
    } catch (error) {}
    setSubmitting(false);
  };

  const [uploadFile] = useUploadFileMutation();

  const [files, setFiles] = useState({
    aadharFile: null,
    profilePic: null,
  });

  const getRoleCode = () => {
    console.log("userInfo?.attributes", userInfo?.attributes?.role);
    if (userInfo?.attributes?.role?.at(0) == "Vessel Owner") {
      setRoleCode("vessel-owner");
    } else if (userInfo?.attributes?.role?.at(0) == "Builder") {
      setRoleCode("builder");
    } else if (userInfo?.attributes?.role?.at(0) == "Yard Owner") {
      setRoleCode("yard-owner");
    }
  };

  const registrationStyles = {
    zIndex: "99",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  };

  if (roleLoading) return <Loader />;

  return (
    <Container className="dashboard-container  " style={registrationStyles}>
      <Container>
        <div className="d-flex justify-content-center">
          <img src="../../../assets/images/default-logo.jpg" />
        </div>
        <Row>
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={{
              aadharFile: "",
              aadharNo: "",
              districtId: "",
              email: "",
              firstName: "",
              houseName: "",
              lastName: "",
              mobile: null,
              pinCode: "",
              postOffice: "",
              profilePic: "",
              roleId: null,
              profilePic: "",
              city: "",
              //userInfo: userInfo,
            }}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleChange,
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="forms" onSubmit={handleSubmit}>
                <Container>
                  <CommonModal
                    {...message}
                    description=""
                    buttonText="OK"
                    handleClose={() => {
                      setMessage((message) => ({
                        ...message,
                        show: false,
                      }));
                      if (message.isSuccess) props.changeRoutes(roleCode);
                    }}
                  />
                  <Col>
                    <Row className="justify-content-center">
                      <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Card className="card">
                          <Container>
                            <Row>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  id="houseName"
                                  label="House Name/Number"
                                  name="houseName"
                                  type="text"
                                  isRequired={true}
                                  required={true}
                                  value={values.houseName}
                                  onChange={handleChange}
                                  error={errors.houseName}
                                  touched={touched.houseName}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SelectBox
                                  label="District"
                                  id="districtId"
                                  name="districtId"
                                  options={districtData?.result?.data ?? []}
                                  value={values.districtId}
                                  isRequired={true}
                                  isIdSelect={true}
                                  onChange={handleChange}
                                  error={errors.districtId}
                                  isTouched={touched.districtId}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="City"
                                  id="city"
                                  name="city"
                                  type="text"
                                  isRequired={true}
                                  required={true}
                                  onChange={handleChange}
                                  value={values.city}
                                  error={errors.city}
                                  touched={touched.city}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Post Office"
                                  id="postOffice"
                                  name="postOffice"
                                  type="text"
                                  isRequired={true}
                                  required={true}
                                  onChange={handleChange}
                                  value={values.postOffice}
                                  error={errors.postOffice}
                                  touched={touched.postOffice}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Pincode"
                                  id="pinCode"
                                  name="pinCode"
                                  type="text"
                                  isRequired={true}
                                  required={true}
                                  onChange={handleChange}
                                  value={values.pinCode}
                                  error={errors.pinCode}
                                  touched={touched.pinCode}
                                />
                              </Col>
                              {/* <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Aadhaar No."
                                  id="aadharNo"
                                  name="aadharNo"
                                  type="text"
                                  isRequired={true}
                                  required={true}
                                  onChange={handleChange}
                                  value={values.aadharNo}
                                />
                              </Col> */}
                            </Row>
                            <Row>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <FileSelector
                                  id="aadharFile"
                                  label="Upload Aadhaar"
                                  name="aadharFile"
                                  required={true}
                                  onChange={handleFileSelect}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <FileSelector
                                  id="profilePic"
                                  label="Upload your photo"
                                  name="profilePic"
                                  required={true}
                                  onChange={handleFileSelect}
                                />
                              </Col>
                            </Row>
                          </Container>
                          <Container>
                            <div className="d-flex buttons-container justify-content-center p-5">
                              <Button
                                type="button"
                                label="Cancel"
                                className="btn-sucess float-right btn-outline"
                                //  onClick={}
                                onClick={() => {
                                  UserService.doLogout();
                                  localStorage.clear();
                                }}
                              />
                              <Button
                                type="submit"
                                label="Submit"
                                className="btn-sucess float-right btn-default"
                              />
                            </div>
                          </Container>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Container>
              </form>
            )}
          </Formik>
        </Row>
      </Container>
    </Container>
  );
}

export default Index;
