import { pagination } from "../../../../utils/constants";

export const fetchUser = (build) => {
  return build.query({
    query: ({ searchParams = "", page = "" }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/user?${params.join("&")}`;
      return url;
    },
  });
};
export const fetchUserbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/user/${id}`;
      return url;
    },
  });
};
export const fetchLoggedUser = (build) => {
  return build.query({
    query: ({}) => {
      const url = `/user/loggedUser`;
      return url;
    },
  });
};
export const addUser = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/user`,
      method: "post",
      body: data,
    }),
  });
export const updateUser = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `user/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const deleteUser = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `user/${id}`,
      method: "DELETE",
    }),
  });
