import { lazy } from "react";
import {
  faDashboard,
  faCircleCheck,
  faClipboardList,
  faUserPen,
  faClipboard,
  faShip,
} from "@fortawesome/free-solid-svg-icons";
import { ACTIVE, vesselHistoryTypes } from "../utils/constants";
import CarvingList from "../pages/Carving/List/CarvingList";
import DocumentVerification from "../components/DocumentVerification";

const PreApprovalList = lazy(() =>
  import("../pages/PreApproval/List/PreApprovalList")
);
const PreApprovalDetails = lazy(() =>
  import("../pages/PreApproval/Request/PreApprovalRequestDetails")
);
const SurveyRequestList = lazy(() =>
  import("../pages/Survey/List/SurveyRequestList")
);
const SurveyRequestDetails = lazy(() =>
  import("../pages/Survey/Request/SurveyRequestDetails")
);
const RegistrationList = lazy(() =>
  import("../pages/Registration/List/RegistrationList")
);
const RegistrationDetails = lazy(() =>
  import("../pages/Registration/Request/RegistrationDetails")
);
const Dashboard = lazy(() => import("../pages/Dashboard"));

const StageVerification = lazy(() =>
  import("../components/CommonStageVerification")
);

const StageVerificationList = lazy(() =>
  import("../pages/StageVerification/List")
);

const StageVerificationDetails = lazy(() =>
  import("../pages/StageVerification/Request")
);

const VesselHistory = lazy(() =>
  import("../pages/VesselHistory/VesselHistory")
);

const UserVesselList = lazy(() => import("../pages/Vessels/List"));

export const NavalArchitectRoutes = [
  {
    name: "Dashboard",
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    component: <Dashboard crumbs="Dashboard" />,
  },
  {
    name: "keel-laying-request-list",
    component: (
      <StageVerificationList
        type="Keel laying"
        crumbs="New Vessel Requests/Stage Verification/Keel Laying"
        requestTypeCode="new"
      />
    ),
    path: "/keel-laying-requests",
  },
  {
    name: "launching-request-list",
    component: (
      <StageVerificationList
        type="Launching"
        crumbs="New Vessel Requests/Stage Verification/Launching"
        requestTypeCode="new"
      />
    ),
    path: "/launching-requests",
  },
  {
    name: "stability-request-list",
    component: (
      <StageVerificationList
        type="Stability"
        crumbs="New Vessel Requests/Stage Verification/Stability"
        requestTypeCode="new"
      />
    ),
    path: "/stability-requests",
  },
  {
    name: "Vessel Requests",
    icon: faCircleCheck,
    path: "/vessel-requests",
    component: <StageVerificationList />,
    mainMenu: true,
    childPaths: ["/vessel-request-details", "/vessel-requests/vessel-history"],
  },
  {
    name: "Keel Laying",
    component: <StageVerificationList />,
    path: "/keel-laying-requests",
    icon: faClipboard,
    mainMenu: true,
    childPaths: [
      "/stage-verification-keel-laying",
      "/keel-laying-requests/vessel-history",
    ],
  },
  {
    name: "Launching",
    component: <StageVerificationList />,
    path: "/launching-requests",
    icon: faClipboard,
    mainMenu: true,
    childPaths: [
      "/stage-verification-launching",
      "/launching-requests/vessel-history",
    ],
  },
  {
    name: "Stability",
    component: <StageVerificationList />,
    path: "/stability-requests",
    icon: faClipboard,
    mainMenu: true,
    childPaths: [
      "/stage-verification-stability",
      "/stability-requests/vessel-history",
    ],
  },
  {
    name: "Additional Request",
    component: <StageVerificationList />,
    path: "/additional-requests",
    icon: faClipboard,
    mainMenu: true,
  },
  {
    name: "stage-verification-details",
    component: (
      <StageVerificationDetails crumbs="New Vessel Requests/Stage Verification" />
    ),
    path: "/stage-verification-details",
  },
  {
    name: "Survey",
    icon: faClipboardList,
    path: "/survey-request",
    component: <SurveyRequestList crumbs="Survey/Survey Requests" />,
    mainMenu: true,
    childPaths: ["/survey-request-details", "/survey-request/vessel-history"],
  },
  {
    name: "Survey Request Details",
    component: <SurveyRequestDetails crumbs="Survey / #Reference Number" />,
    path: "/survey-request-details",
  },
  {
    name: "Registration",
    icon: faUserPen,
    path: "/registration-requests",
    component: <RegistrationList crumbs="Registration/Registration Requests" />,
    mainMenu: true,
    subMenuHead: true,
    childrens: [
      {
        name: "Carving",
        icon: faClipboardList,
        path: "/carving-requests",
        component: <CarvingList crumbs="Registration/Carving Requests" />,

        childPaths: [
          "/carving-request-details",
          "/carving-requests/vessel-history",
        ],
      },
    ],
    childPaths: [
      "/registration-request-details",
      "/registration-requests/vessel-history",
    ],
  },
  // {
  //   name: "Registration",
  //   icon: faUserPen,
  //   path: "/registration-requests",
  //   component: <RegistrationList crumbs="Registration/Registration Requests" />,
  //   mainMenu: true,
  //   childPaths: [
  //     "/registration-request-details",
  //     "/registration-requests/vessel-history",
  //   ],
  // },

  {
    name: "Registration Request Details",
    component: (
      <RegistrationDetails crumbs="Registration Request/ #Reference Number" />
    ),
    path: "/registration-request-details",
  },
  {
    name: "Stage Verification",
    component: <StageVerification />,
    path: "/stage-verification",
  },
  {
    name: "Owner Documents",
    component: (
      <DocumentVerification crumbs="Vessel Requests/Vessel Request Details" />
    ),
    path: "/vessel-request-details/owner-documents",
  },
  {
    name: "Vessel History",
    path: "/vessel-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Vessel Requests / Vessel History"
        type={vesselHistoryTypes.PRE_APPROVAL}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/keel-laying-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Keel Laying / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/launching-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Launching / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/stability-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Stability / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/survey-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Survey / Vessel History"
        type={vesselHistoryTypes.SURVEY}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/registration-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Registration / Vessel History"
        type={vesselHistoryTypes.REGISTRATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/carving-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Carving / Vessel History"
        type={vesselHistoryTypes.CARVING}
      />
    ),
  },
  {
    name: "Vessels",
    component: <UserVesselList crumbs="Vessels" />,
    path: "/vessels",
    mainMenu: true,
    icon: faShip,
    childPaths: ["/vessels/vessel-history"],
  },
  {
    name: "Vessel History",
    path: "/vessels/vessel-history",
    component: <VesselHistory crumbs="Vessels / Vessel History" type={null} />,
  },
];
