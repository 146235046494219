import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import Form from "react-bootstrap/Form";

function SelectBox({
  options,
  value,
  onChange,
  onBlur,
  label,
  name,
  choose,
  isRequired = false,
  isTouched = false,
  error,
  label2,
  className,
  isIdSelect = false,
  labelHide = false,
  isDisabled = false,
}) {
  const selectValue = (option) => {
    let value = option.value;

    if (isIdSelect) {
      value = option._id;
    }
    return value;
  };

  const selectLabel = (option) => {
    let value = option.value;
    if (option.portName) {
      value = option.portName;
    } else if (option.zoneName) {
      value = option.zoneName;
    } else if (option.lsgdName) {
      value = option.lsgdName;
    } else if (option.districtName) {
      value = option.districtName;
    } else if (option.roleName) {
      value = option.roleName;
    } else if (option.vesselSubTypeName) {
      value = option.vesselSubTypeName;
    } else if (option.vesselName) {
      value = option.vesselName;
    } else if (option.vesselTypeName) {
      value = option.vesselTypeName;
    } else if (option.vesselCategoryName) {
      value = option.vesselCategoryName;
    } else if (option.builderName) {
      value = option.builderName;
    } else if (option.requestType) {
      value = option.requestType;
    } else if (option.preApprovalType) {
      value = option.preApprovalType;
    } else if (option.firstName) {
      value = option.firstName;
    } else if (option.surveyName) {
      value = option.surveyName;
    } else if (option.builder) {
      value = option.builder;
    } else if (option.label) {
      value = option.label;
    }

    return value;
  };

  const toLower = (label) => {
    const value = label ? label.toLowerCase() : label;
    return value;
  };
  return (
    <div className="text-box">
      {choose && (
        <label htmlFor={name}>
          {label2}
          {isRequired && <span className="required-indicator">*</span>}
        </label>
      )}

      {choose !== true && label && !labelHide && (
        <label htmlFor={name}>
          {label}
          {isRequired && <span className="required-indicator">*</span>}
        </label>
      )}
      <Form.Select
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={"text-input"}
        name={name}
        disabled={isDisabled}
      >
        {choose !== true ? (
          <option key={"id"} id={"id"} value={""}>
            {`Please select ${toLower(label)} `}
          </option>
        ) : (
          <option key={"id"} id={"id"} value={""}>
            {`Select `}
          </option>
        )}

        {options &&
          options.map((option) => (
            <option
              key={option._id ? option._id : option.value}
              id={option._id ? option._id : option.value}
              value={selectValue(option)}
            >
              {selectLabel(option)}
            </option>
          ))}
      </Form.Select>
      {isTouched && error && <p className="error-message">{error}</p>}
    </div>
  );
}

SelectBox.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  isRequired: PropTypes.bool,
  isTouched: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.any,
};

export default SelectBox;
