import { Accordion, Col, Row } from "react-bootstrap";

function SurveyDetails({ additionalData = {}, vesselData = {} }) {
  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>Survey Application</Accordion.Header>
      <Accordion.Body>
        <h4 className="my-3">Survey Details</h4>
        <Row className="d-flex">
          <Row>
            <Col md={4} className="mb-3">
              <p className="field ">Survey Type</p>
              <p className="display-field fw-bold">
                {vesselData?.surveyType?.surveyName}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Registered Tonnage </p>
              <p className="display-field fw-bold">
                {additionalData?.regTonnage}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Year of Build</p>
              <p className="display-field fw-bold">
                {additionalData?.yearOfBuild}
              </p>
            </Col>
            {vesselData?.surveyType?.surveyName !== "Initial" ? (
              <>
                <Col md={4}>
                  <p className="field ">Date of Last Survey</p>
                  <p className="display-field fw-bold">
                    {additionalData?.dateOfLastSurvey}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Place of Last Survey</p>
                  <p className="display-field fw-bold">
                    {additionalData?.placeOfLastSurvey}
                  </p>
                </Col>
              </>
            ) : null}
          </Row>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default SurveyDetails;
