import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  addVesselType,
  fetchVesselTypes,
  fetchVesselTypebyId,
  updateVesselType,
  addVesselCategory,
  fetchVesselCategory,
  fetchVesselCategorybyId,
  updateVesselCategory,
  deleteVesselType,
  deleteVesselCategory,
  fetchVesselBuilder,
  fetchVesselBuilderbyId,
  updateVesselBuilder,
  deleteVesselBuilder,
  addVesselBuilder,
} from "./Vessel";

export const VesselApi = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addVesselType: addVesselType(build),
    fetchVesselTypes: fetchVesselTypes(build),
    fetchVesselTypebyId: fetchVesselTypebyId(build),
    updateVesselType: updateVesselType(build),
    addVesselCategory: addVesselCategory(build),
    fetchVesselCategory: fetchVesselCategory(build),
    fetchVesselCategorybyId: fetchVesselCategorybyId(build),
    updateVesselCategory: updateVesselCategory(build),
    deleteVesselType: deleteVesselType(build),
    deleteVesselCategory: deleteVesselCategory(build),
    fetchVesselBuilder: fetchVesselBuilder(build),
    fetchVesselBuilderbyId: fetchVesselBuilderbyId(build),
    updateVesselBuilder: updateVesselBuilder(build),
    deleteVesselBuilder: deleteVesselBuilder(build),
    addVesselBuilder: addVesselBuilder(build),
  }),
  overrideExisting: false,
});

export const {
  useAddVesselTypeMutation,
  useLazyFetchVesselTypesQuery,
  useLazyFetchVesselTypebyIdQuery,
  useUpdateVesselTypeMutation,
  useAddVesselCategoryMutation,
  useLazyFetchVesselCategoryQuery,
  useLazyFetchVesselCategorybyIdQuery,
  useUpdateVesselCategoryMutation,
  useDeleteVesselTypeMutation,
  useDeleteVesselCategoryMutation,
  useAddVesselBuilderMutation,
  useLazyFetchVesselBuilderQuery,
  useLazyFetchVesselBuilderbyIdQuery,
  useUpdateVesselBuilderMutation,
  useDeleteVesselBuilderMutation,
} = VesselApi;
